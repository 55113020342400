import { AuthenticateByPhoneTokenModel, AuthenticatedModel, AuthenticateModel, RefreshTokenModel, Rights } from '../models/api-models';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthenticationService as ApiAuthenticationService } from './api-services';
import LocalStorageHelper from '../helpers/local-storage.helper';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseAuthenticationService {
  protected static PrivateEmailStorageKey: string = 'pdPrivateLoggedInEmail';
  protected static PublicEmailStorageKey: string = 'pdPublicLoggedInEmail';

  protected authenticatedModel: AuthenticatedModel | null = null;
  public $authenticated = new ReplaySubject<AuthenticatedModel | null>(1);

  constructor(
    protected readonly authorizationService: ApiAuthenticationService,
    protected readonly authenticationKey: string
  ) {
    this.authenticatedModel = LocalStorageHelper.getItemFromObject<AuthenticatedModel>(this.authenticationKey);

    this.$authenticated.next(this.authenticatedModel);
  }

  public abstract setLoginInfo(data: AuthenticatedModel | null): void;

  public logout(): void {
    const refreshToken =this.getRefreshToken();

    if(refreshToken) {
      this.authorizationService.logout(refreshToken)
      .pipe(finalize(() => this.setLoginInfo(null)))
      .subscribe();
    }
    else {
      this.setLoginInfo(null);
    }
  }

  public setAuthentication(value: boolean): void {
    LocalStorageHelper.setItemFromObject<boolean>(this.authenticationKey, value);
  }

  public static getPrivateEmail(): string | null {
    return LocalStorageHelper.getItemFromObject<string>(BaseAuthenticationService.PrivateEmailStorageKey);
  }

  public static getPublicEmail(): string | null {
    return LocalStorageHelper.getItemFromObject<string>(BaseAuthenticationService.PublicEmailStorageKey);
  }

  public getToken(): string | null {
    return this.authenticatedModel?.token ?? null;
  }

  public getRefreshToken(): string | null {
    return this.authenticatedModel?.refreshToken ?? null;
  }

  public tokenExpired(): boolean {
    const token = this.getToken();

    if (!token) {
      return true;
    }

    const expiryDate = this.decodeJwt(token).exp;

    return Math.floor(new Date().getTime() / 1000) >= expiryDate;
  }

  protected decodeJwt(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }

  public getDisplayName(): string | null {
    return this.authenticatedModel?.displayName ?? null;
  }

  public getLastName(): string | null {
    return this.authenticatedModel?.lastname ?? null;
  }

  public getFirstName(): string | null {
    return this.authenticatedModel?.firstname ?? null;
  }

  public getEmail(): string | null {
    return this.authenticatedModel?.email ?? null;
  }

  public getPhoneNumber(): string | null {
    return this.authenticatedModel?.phoneNumber ?? null;
  }

  public getUserId(): string | null {
    return this.authenticatedModel?.userId ?? null;
  }

  public getRoles(): string[] | null {
    return this.authenticatedModel?.roleKeys ?? null;
  }

  public getRights(): Rights[] | null {
    return this.authenticatedModel?.rightKeys ?? null;
  }

  public hasRight(right: Rights): boolean {
    if (!this.authenticatedModel) {
      return false;
    }

    return this.authenticatedModel.rightKeys.includes(right);
  }

  public hasAnyRight(rights: Rights[]): boolean {
    if (!this.authenticatedModel) {
      return false;
    }

    return this.authenticatedModel.rightKeys.some(right => rights.includes(right));
  }

  public getEmailConfirmed(): boolean | null {
    return this.authenticatedModel?.emailConfirmed ?? null;
  }

  public getPersonId(): string | null {
    return this.authenticatedModel?.personId ?? null;
  }

  public isAuthenticated(): boolean {
    return !this.tokenExpired();
  }

  authenticate(loginName: string, password: string, token: string | undefined = undefined): Observable<AuthenticatedModel | null> {
    if (!token) {
      const authModel: AuthenticateModel = {
        loginName: loginName,
        password: password
      };
      if (authModel) {
        return this.authorizationService.authenticate(authModel)
          .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
            this.setLoginInfo(loggedInModel);
            return loggedInModel;
          }));
      } else {
        return of(null)
      }
    } else {
      const authModel: AuthenticateByPhoneTokenModel = {
        loginName: loginName,
        password: password,
        token: token
      };
      if (authModel) {
        return this.authorizationService.authenticateByPhoneToken(authModel)
          .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
            this.setLoginInfo(loggedInModel);
            return loggedInModel;
          }));
      } else {
        return of(null)
      }
    }

  }

  public authenticateByCode(code: string, relation: string): Observable<AuthenticatedModel | null> {
    return this.authorizationService.authenticateByCode(code, relation)
      .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
        this.setLoginInfo(loggedInModel);
        return loggedInModel;
      }));
  }

  refreshToken(refreshToken?: string | null): Observable<AuthenticatedModel | null> {
    if (!refreshToken) {
      refreshToken = this.getRefreshToken();
    }

    if (refreshToken) {
      const refreshModel: RefreshTokenModel = {
        refreshToken: refreshToken
      };

      return this.authorizationService.refreshToken(refreshModel)
        .pipe(tap((loggedInModel: AuthenticatedModel | null) => {
          this.setLoginInfo(loggedInModel);

          return loggedInModel;
        }),
        catchError((error, caught) => {
          this.setLoginInfo(null);

          return of(null);
        }));
    }
    else {
      return of(null)
    }
  }
}
